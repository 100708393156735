@import "src/scss/module";

.trending {

  :global {

    [role="button"] {

      &[href=""] {
        pointer-events: none;
      }
    }
  }
}
